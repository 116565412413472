import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useRouter, useSearchParams } from "next/navigation";
import { useAppState } from "../../appContext";
import { useFetchUtilityData } from "../../hooks";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ResponsiveAppBar = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [state, dispatch] = useAppState();
  const pages = [
    { title: "Events", route: "/events" },
    { title: "Venues", route: "/venues" },
    { title: "Bands", route: "/bands" },
    { title: "Map", route: "/map" },
  ];
  const { utilityData } = useFetchUtilityData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint as needed

  React.useEffect(() => {
    if (utilityData) {
      dispatch({
        type: "SET_MULTIPLE_PROPS",
        payload: {
          states: utilityData.states,
        },
      });
    }
  }, [utilityData]);

  const navigateToSearchResuls = (searchTerm) => {
    const params = new URLSearchParams(searchParams.toString());
    if (searchTerm) {
      params.set("q", searchTerm);
      router.push(`/search?${params.toString()}`);
    } else {
      router.push("/");
    }
  };

  return (
    <AppBar sx={{ background: "#334e65", color: "#fff" }} position="fixed">
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          //justifyContent: "space-between",
        }}
      >
        <div>
          <a
            href="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              fontFamily: "monospace",
              fontWeight: 900,
              letterSpacing: ".3rem",
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
              lineHeight: "1",
              marginRight: "100px",
            }}
          >
            <img
              style={{ height: "30px", width: "40px", marginRight: "10px" }}
              src="/wtlogo.jpg"
              alt="Webtunes Logo"
            />
            Webtunes
          </a>
        </div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.title}
              onClick={() => router.push(page.route)}
              sx={{
                my: isMobile ? 1 : 0,
                mr: 1,
                color: "#fff",
                display: "block",
              }}
            >
              {page.title}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;
