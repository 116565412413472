// components/Footer.js
import { Box, Typography, IconButton } from "@mui/material";
import { Facebook, Twitter, Instagram, YouTube } from "@mui/icons-material";
import { FaSpotify } from "react-icons/fa";

const Footer = () => {
return (
<Box
    component="footer"
    sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 2,
    backgroundColor: "#333",
    color: "#fff",
    }}
>
    <Typography variant="h6" sx={{ mb: 1 }}>
    Follow Us
    </Typography>
    <Box>
    <IconButton
        component="a"
        href="https://www.facebook.com/webtunesny/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: "#fff" }}
    >
        <Facebook />
    </IconButton>
    <IconButton
        component="a"
        href="https://x.com/johnfromny"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: "#fff" }}
    >
        <Twitter />
    </IconButton>
    <IconButton
        component="a"
        href="https://www.instagram.com/webtuneslive/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: "#fff" }}
    >
        <Instagram />
    </IconButton>
    <IconButton
        component="a"
        href="https://www.youtube.com/@webtunes"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: "#fff" }}
    >
        <YouTube />
    </IconButton>
    <IconButton
        component="a"
        href="https://open.spotify.com/playlist/6AJhXn4p7yknyln5tAZ06P" // Link to your Spotify page
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: "#1DB954" }} // Spotify's green color
    >
        <FaSpotify size={24} /> {/* Set icon size as desired */}
    </IconButton>
    </Box>
    <Typography variant="body2" sx={{ mt: 1 }}>
    © {new Date().getFullYear()} Webtunes. All rights reserved.
    </Typography>
</Box>
);
};

export default Footer;
