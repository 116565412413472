"use client";
import "./globals.css";
import { SnackbarProvider } from "notistack";
import "mapbox-gl/dist/mapbox-gl.css";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { AppContextProvider } from "@/appContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "@/app/theme";
import "@/app/App.css";
import ResponsiveAppBar from "@/components/Navbar/ResponsiveAppBar";
import { Box } from "@mui/material";
import Head from "next/head";
import { Suspense } from "react";
import { Skeleton } from "@mui/material";
import Footer from "@/components/Footer";

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <Head>
        <title>Webtunes Users</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Head>
      <body>
        <SnackbarProvider>
          <ThemeProvider theme={theme}>
            <div>
              <AppContextProvider>
                <ResponsiveAppBar />

                {/* Main Content */}
                <Box
                  sx={{ paddingTop: "60px", minHeight: "calc(100vh - 150px)" }}
                >
                  <Suspense
                    fallback={<Skeleton height={100} animation="wave" />}
                  >
                    {children}
                  </Suspense>
                </Box>
              </AppContextProvider>
            </div>
          </ThemeProvider>
        </SnackbarProvider>
        <Footer />
      </body>
    </html>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
