import { createTheme } from "@mui/material";

const theme = createTheme({
  spacing: 8, // Adjust the spacing value as needed
  // Add other theme configurations here
  primaryColor: "#334e65",
  fontHeading: "#157dd6",
  fontSubHeading: "#b22222",
  primaryHoverColor: "#243848",
  palette: {
    primary: {
      main: "#334e65",
    },
  },
});

export default theme;